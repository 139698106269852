import * as React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <h1>short links redirection server</h1>
  </Layout>
)

export default IndexPage

